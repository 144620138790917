// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { environment } from '../../../../../Environment/Environment'
// ** Axios Imports
import axios from 'axios'

const accessUrl = localStorage.getItem('accessUrl')
const apiUrl = environment.apiUrl


export const getTasks = createAsyncThunk('appTodo/getTasks', async params => {
  const response = await axios.post(`${apiUrl}/toDoList`, { params, accessUrl })
  const responseData = response.data.data.tasks
  if (responseData) {
    responseData.map((m) => (
      m.isCompleted = m.isCompleted === '1'
    ))
    responseData.map((m) => (
      m.isDeleted = m.isDeleted === '1'
    ))
    responseData.map((m) => (
      m.isImportant = m.isImportant === '1'
    ))
    responseData.map((m) => (
      m.assignee.avatar = `${environment.baseUrl}${m.assignee.avatar}`
    ))
  }
  return {
    params,
    data: responseData
  }
})

export const addTask = createAsyncThunk('appTodo/addTask', async (task, { dispatch, getState }) => {
  const response = await axios.post(`${apiUrl}/toDoCreate`, { task, accessUrl })
  await dispatch(getTasks(getState().RMtodo.params))
  return response.data
})

export const updateTask = createAsyncThunk('appTodo/updateTask', async (task, { dispatch, getState }) => {
  const response = await axios.post(`${apiUrl}/toDoUpdate`, { task, accessUrl })
  await dispatch(getTasks(getState().RMtodo.params))
  return response.data
})

export const deleteTask = createAsyncThunk('appTodo/deleteTask', async (taskId, { dispatch, getState }) => {
  const response = await axios.post(`${apiUrl}/toDoDelete`, { taskId, accessUrl })
  await dispatch(getTasks(getState().RMtodo.params))
  return response.data
})

export const appTodoSlice = createSlice({
  name: 'appTodo',
  initialState: {
    tasks: [],
    selectedTask: {},
    params: {
      filter: '',
      q: '',
      sort: '',
      tag: ''
    }
  },
  reducers: {
    reOrderTasks: (state, action) => {
      state.tasks = action.payload
    },
    selectTask: (state, action) => {
      state.selectedTask = action.payload
    }
  },
  extraReducers: builder => {
    builder.addCase(getTasks.fulfilled, (state, action) => {
      state.tasks = action.payload.data
      state.params = action.payload.params
    })
  }
})

export const { reOrderTasks, selectTask } = appTodoSlice.actions

export default appTodoSlice.reducer
