// You can customize the template with the help of this file
//Template config options
// import {environment} from '../Environment/Environment'
// const usersData = JSON.parse(localStorage.getItem('userData'))
// console.log(usersData)
// const logoImage = usersData ? usersData.loader : ''
// const baseUrl = environment.baseUrl


const themeConfig = {
  app: {
    appName: 'EGBS',
    // appLogoImage: baseUrl + logoImage
    appLogoImage: require('@src/assets/images/logo/EGBS.png').default
  },
  layout: {
    isRTL: false,
    skin: 'light', // light, dark, bordered, semi-dark
    routerTransition: 'fadeIn', // fadeIn, fadeInLeft, zoomIn, none or check this for more transition https://animate.style/
    type: 'vertical', // vertical, horizontal
    contentWidth: 'full', // full, boxed
    menu: {
      isHidden: false,
      isCollapsed: false
    },
    navbar: {
      // ? For horizontal menu, navbar type will work for navMenu type
      type: 'floating', // static , sticky , floating, hidden
      backgroundColor: 'white' // BS color options [primary, success, etc]
    },
    footer: {
      type: 'static' // static, sticky, hidden
    },
    customizer: true,
    scrollTop: true // Enable scroll to top button
  }
}

export default themeConfig

