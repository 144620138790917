import { environment } from "../../../Environment/Environment"
// ** Auth Endpoints
export default {
  loginEndpoint: `${environment.apiUrl}/login`,
  registerEndpoint:  `${environment.apiUrl}/register`,
  refreshEndpoint: `${environment.apiUrl}/refreshToken`,
  logoutEndpoint: '/jwt/logout',

  // ** This will be prefixed in authorization header with token
  // ? e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // ** Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken'
}

