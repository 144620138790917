// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { environment } from '../../../../../Environment/Environment'

// ** Axios Imports
import axios from 'axios'

const accessUrl = localStorage.getItem('accessUrl')
const events = []
const apiUrl = environment.apiUrl

export const fetchEvents = createAsyncThunk('appCalendar/fetchEvents', async calendars => {
  const response = await axios.post(`${apiUrl}/calenderLists`, { calendars, accessUrl })
  const responseData = response.data.data.events
  if (responseData) {
    responseData.map((m) => (
      m.allDay = m.allDay === 'true' 
    ))
  }
  return responseData
})

export const fetchSelectedCalendars = createAsyncThunk('appCalendar/fetchSelectedCalendars', async () => {
  const response = await axios.post(`${apiUrl}/eventList`, { accessUrl })
  const eventArray = response.data.data?.eventList
  for (let i = 0; i < eventArray.length; i++) {
     events.push(eventArray[i].value)
  }
  return events
})

export const addEvent = createAsyncThunk('appCalendar/addEvent', async (event, { dispatch, getState }) => {
  console.log(event)
  await axios.post(`${apiUrl}/calendercreate`, { event, accessUrl })
  await dispatch(fetchEvents(getState().LMcalendar.selectedCalendars))
  return event
})

export const updateEvent = createAsyncThunk('appCalendar/updateEvent', async (event, { dispatch, getState }) => {
  await axios.post(`${apiUrl}/calenderupdate`, { event, accessUrl })
  await dispatch(fetchEvents(getState().LMcalendar.selectedCalendars))
  return event
})

export const updateFilter = createAsyncThunk('appCalendar/updateFilter', async (filter, { dispatch, getState }) => {
  if (getState().LMcalendar.selectedCalendars.includes(filter)) {
    await dispatch(fetchEvents(getState().LMcalendar.selectedCalendars.filter(i => i !== filter)))
  } else {
    await dispatch(fetchEvents([...getState().LMcalendar.selectedCalendars, filter]))
  }
  return filter
})

export const updateAllFilters = createAsyncThunk('appCalendar/updateAllFilters', async (value, { dispatch }) => {
  if (value === true) {
    await dispatch(fetchEvents(events))
  } else {
    await dispatch(fetchEvents([]))
  }
  return value
})

export const removeEvent = createAsyncThunk('appCalendar/removeEvent', async id => {
  await axios.post(`${apiUrl}/calenderdelete`, { id, accessUrl })
  return id
})

export const appCalendarSlice = createSlice({
  name: 'appCalendar',
  initialState: {
    events: [],
    selectedEvent: {},
    selectedCalendars: []
    // selectedCalendars: ['Personal', 'Business', 'Family', 'Holiday', 'ETC']
  },
  reducers: {
    selectEvent: (state, action) => {
      state.selectedEvent = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchSelectedCalendars.fulfilled, (state, action) => {
        state.selectedCalendars = action.payload
      })
      .addCase(fetchEvents.fulfilled, (state, action) => {
        state.events = action.payload
      })
      .addCase(updateFilter.fulfilled, (state, action) => {
        if (state.selectedCalendars.includes(action.payload)) {
          state.selectedCalendars.splice(state.selectedCalendars.indexOf(action.payload), 1)
        } else {
          state.selectedCalendars.push(action.payload)
        }
      })
      .addCase(updateAllFilters.fulfilled, (state, action) => {
        const value = action.payload
        let selected = []
        if (value === true) {
          selected = events
        } else {
          selected = []
        }
        state.selectedCalendars = selected
      })
  }
})

export const { selectEvent } = appCalendarSlice.actions

export default appCalendarSlice.reducer
