// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'
// ** UseJWT import to get config
export const portalDataSlice = createSlice({
  name: 'portalDetails',
  initialState: {
    userData: []
  },
  reducers: {
    getPortalDetails: (state, action) => {
      state.userData = action.payload
    }
  }
})

export const { getPortalDetails }  = portalDataSlice.actions

export default portalDataSlice.reducer
